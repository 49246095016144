<template>
  <div class="nodewrappar" :class="[
    showCon
      ? 'animate__animated animate__zoomIn'
      : 'animate__animated animate__zoomOut',
  ]">
    <div class="stardetails-bg flex">
      <div class="closeicon" @click="handlerClose"></div>
      <div class="paybtn animate__animated animate__zoomInUp animate__delay-.5s" v-if="info.status == 2"
        @click="handlerShowPay">
        {{ $t("node.desc32") }}
      </div>
      <div class="paybtn animate__animated animate__zoomInUp animate__delay-.5s" @click="handlerBuy"
        v-else-if="info.status == 1 && info.landStatus == 1">
        {{ $t("market.desc133") }}
      </div>
      <div class="paybtn greybtn animate__animated animate__zoomInUp animate__delay-.5s"
        v-else-if="info.status == 1 && info.landStatus == 0">
        {{ $t("node.desc64") }}
      </div>
      <div class="paybtn greybtn animate__animated animate__zoomInUp animate__delay-.5s"
        v-else-if="info.status == 1 && info.landStatus == 2">
        {{ $t("market.desc143") }}
      </div>
      <div class="paybtn greybtn animate__animated animate__zoomInUp animate__delay-.5s" v-else-if="info.status == 0">
        {{ $t("planet.desc67") }}
      </div>
      <div class="slider-top">
        <div class="top-name">
          <div>{{ $t("home.desc54") }}</div>
          <p>{{ info.coord }}</p>
        </div>
        <div class="top-img" v-if="info && info.icon">
          <star-img :sphereImg="info.icon" :starWidth="370" :starHeight="370" />
        </div>
      </div>

      <div class="slider-left animate__animated animate__bounceIn animate__delay-0.5s">
        <div class="planet-title" v-if="info.status == 2">
          {{ $t("node.desc18", { n1: info.typeName }) }}
        </div>
        <div class="planet-title" v-else-if="info.status == 1">
          {{ $t("node.desc65", { n1: info.userName }) }}
        </div>
        <div class="planet-title" v-else>
          {{ $t("node.desc66", { n1: info.typeName }) }}
        </div>
        <!-- <div class="info-btn flexcenter" @click="handlerOther">{{ $t('node.desc23') }}</div> -->
        <div class="planet-info">
          <div class="info-desc flex">
            <div class="price">
              <div>{{ $t("node.desc20") }}</div>
              <p>{{ info.typeName }}</p>
            </div>
            <div class="price">
              <div>{{ $t("node.desc8") }}</div>
              <p>{{ info.coord }}</p>
            </div>

            <div class="price">
              <div>
                {{ $t("node.desc39") }}
                <el-tooltip class="box-item" effect="dark" :content="info.typeId == 1
                  ? $t('node.desc73', { n1: info.landTax })
                  : $t('node.desc74', { n1: info.landTax })
                  " placement="bottom">
                  <img src="../public/images/new/tips.png" alt="" />
                </el-tooltip>
              </div>
              <p>{{ info.landTax }}%</p>
            </div>
            <div class="price">
              <div>{{ $t("node.desc22") }}</div>
              <p>{{ $t("manage.desc16", { n1: info.landCount }) }}</p>
            </div>
            <div class="price">
              <div>{{ $t("node.desc38") }}</div>
              <p>
                {{ info.investMin }} - {{ info.investMax }}
                {{ info.investCoinName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider-center" v-if="info && info.icon">
        <star-img :sphereImg="info.icon" :starSize="140" :isBigShadow="2" />
        <img src="../public/images/new/lineimg.png" alt="" class="img" />
      </div>
      <div class="slider-right animate__animated animate__bounceIn animate__delay-0.5s" v-if="info.status != 1">
        <div class="slider-title">{{ $t("node.desc40") }}</div>
        <div class="slider-input">
          <!-- <input type="text" :placeholder="$t('node.desc25')" /> -->
          {{ info.price }} {{ info.investCoinName }}
        </div>
        <div class="slider-title">{{ $t("node.desc41") }}</div>
        <div class="slider-type flex">
          <div>{{ $t("node.desc27") }}</div>
          <!-- <div class="flexcenter" :class="{ act: act == 1 }">{{ $t('node.desc28') }}</div> -->
        </div>
        <div class="slider-title">{{ $t("node.desc26") }}</div>
        <div class="stardetails-icon">
          <div class="icon-name flex">
            <div class="flex">
              <img :src="info.payCoinIcon" alt="" />{{ info.payCoinName }}
            </div>
            <p>{{ $t("node.desc44", { n1: info.price }) }}</p>
          </div>
          <!-- <el-dropdown @command="handleCommand" trigger="click">
                        <span class="el-dropdown-link">
                            <div class="icon-name flex">
                                <img :src="iconInfo.icon == undefined ? require('../public/images/logoimg.png') : iconInfo.icon"
                                    alt="" />{{ iconInfo.coinSymbol }}
                            </div>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="item.coinId" v-for="item in coinList">
                                    <div class="coin-info flex">
                                        <img :src="item.icon" alt="" />{{ item.coinSymbol }}
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown> -->
        </div>
        <div class="slider-price" v-if="isLogin">
          <div class="price-info flex">
            <div>{{ $t("asset.desc74") }}</div>
            <p class="flex">
              {{ userBalance }} {{ info.payCoinName }}
              <el-tooltip effect="dark" placement="bottom" v-if="userInfo.sourceType == 1 && parseFloat(info.saiBalance) > 0
                ">
                <template #content>
                  <div v-html="$t('node.desc79')"></div>
                </template>
                <img src="../public/images/new/tips.png" alt="" />
              </el-tooltip>
            </p>
          </div>
        </div>
        <div class="slider-tips" v-if="parseFloat(userBalance) < parseFloat(info.price) && isLogin">
          {{ $t("pool.desc68") }}
          <span @click="$router.push(`/recharge?id=${info.payCoinId}&type=1`)">{{ $t("node.desc42") }}</span>
        </div>
        <div class="slider-title titletop" v-if="info.typeId != 1">
          {{ $t("node.desc76") }}
          <el-tooltip class="box-item" raw-content effect="dark" :content="$t('node.desc77')" placement="bottom">
            <img src="../public/images/new/tips.png" alt="" />
          </el-tooltip>
        </div>
        <div class="slider-input" v-if="info.typeId != 1">
          <input type="text" autocomplete="off" :placeholder="$t('node.desc78')" v-model="recommendCode"
            v-if="showInput" />
          <div class="input-div" v-else @click="showInput = true">{{ $t('node.desc78') }}</div>
        </div>
        <div class="slider-check flex">
          <img :src="check
            ? require('../public/images/new/checkimg.png')
            : require('../public/images/new/checkimg1.png')
            " alt="" @click="handlerCheck" />
          <div @click="showRules = true" v-html="$t('node.desc43')"></div>
        </div>
        <div class="slider-btn" @click="handlerShowPay" v-if="info.status == 2">
          {{ $t("node.desc32") }}
        </div>
        <div class="slider-btn gretbtn" v-else>{{ $t("planet.desc67") }}</div>
      </div>
      <div class="slider-right animate__animated animate__bounceIn animate__delay-0.5s" v-else>
        <div class="detriment">
          <div class="detriment-name">
            {{ $t("node.desc62") }}
          </div>
          <div class="detriment-user">{{ info.userName }}</div>
          <div class="detriment-title">{{ $t("manage.desc23") }}</div>
          <div class="detriment-desc">{{ info.description }}</div>
          <div class="detriment-land">{{ $t("node.desc63") }}</div>
          <div class="detriment-num">
            {{ $t("manage.desc16", { n1: info.landCount }) }}
          </div>
        </div>
        <div class="slider-btn gretbtn" v-if="info.landStatus == 0">
          {{ $t("node.desc64") }}
        </div>
        <div class="slider-btn" v-else-if="info.landStatus == 1" @click="handlerBuy">
          {{ $t("market.desc133") }}
        </div>
        <div class="slider-btn gretbtn" v-else-if="info.landStatus == 2">
          {{ $t("market.desc143") }}
        </div>
      </div>
    </div>
    <!--  -->
    <el-dialog v-model="showTrade" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showTrade = false" />
        <div class="modal-title">{{ $t("node.desc45") }}</div>
        <div class="modal-desc">{{ $t("node.desc46") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTrade = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(1)">
            {{ $t("node.desc48") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="showGoogle" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showGoogle = false" />
        <div class="modal-title">{{ $t("home.desc64") }}</div>
        <div class="modal-desc">{{ $t("home.desc65") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showGoogle = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(2)">
            {{ $t("node.desc48") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" v-if="showpsd" />
        </div>
        <!-- <div
          class="modal-tips"
          v-if="
            (userInfo.hasUpdateTranPwd && userInfo.sourceType == 1) ||
            (userInfo.hasUpdateTranPwd && userInfo.sourceType == 2)
          "
        >
          {{ $t("node.desc75") }}
        </div> -->
        <div class="modal-btncon flexcenter" @click="handlerPay" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <el-dialog v-model="showSuccess" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="handlerClose" />
        <div class="modal-title">{{ $t("node.desc54") }}</div>
        <div class="modal-desc">{{ $t("node.desc55") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="handlerClose">
            {{ $t("node.desc56") }}
          </div>
          <div class="flexcenter" @click="handleInfo(info)">
            {{ $t("node.desc57") }}
          </div>
        </div>

      </div>
    </el-dialog>
    <van-popup v-model:show="showRules" round>
      <Article :type="2" @closeModal="closeModal"></Article>
    </van-popup>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watchEffect,
  computed,
} from "vue";
import bus from "@/utils/bus";
import psdinput from "./psdinput.vue";
import starImg from "./starImg.vue";
import * as THREE from "three";
import TrackballControls from "three-trackballcontrols";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Article from "../views/user/article.vue";
export default {
  props: ["nodeId", "level"],
  components: {
    psdinput,
    starImg,
    Article,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    // const windowWidth = localStorage.getItem('ismobile') == 1 ? 350 : 380
    // const windowHeight = localStorage.getItem('ismobile') == 1 ? 280 : 536
    const state = reactive({
      showSuccess: false,
      showRules: false,
      showpsd: false,
      showTrade: false,
      check: true,
      showCon: true,
      act: 0,
      iconInfo: {},
      coinList: [],
      info: {},
      userBalance: 0,
      userInfo: {},
      pasword: "",
      loading: false,
      recommendCode: "",
      isLogin: false,
      showGoogle: false,
      showInput: false
    });
    onMounted((props) => {
      localStorage.getItem("token") == null
        ? (state.isLogin = false)
        : (state.isLogin = true);
      getPlanet();
    });
    const handleInfo = () => {
      proxy
        .$post(proxy.URL.node.modify, {
          planetId: state.info.id,
        })
        .then((res) => {
          if (res.code == 0) {
            let data = {
              icon: res.data.icon,
              days: res.data.editValidDays,
            };
            store.commit("SET_MODIFY_DATA", data);
            router.push(`/manage/modify?id=${state.info.id}`);
          } else {
            proxy.$message.error(res.message);
          }
        });
    };
    const handlerLogpsd = () => {
      state.showTrade = false;
      state.showGoogle = false;
      handlerClose();
      if (localStorage.getItem('ismobile') == 1) {
        router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    }
    const getPlanet = function () {
      proxy
        .$post(proxy.URL.node.planet, {
          id: props.nodeId,
        })
        .then((res) => {
          if (res.code == 0) {
            state.info = res.data;
            state.userBalance = res.data.balance;
            if (state.info.status != 1) {
              state.sphereImg = res.data.icon;
            }
            if (
              state.info.status != 1 &&
              localStorage.getItem("token") != null
            ) {
              //getMoney(res.data.payCoinId)
            }
          } else {
            proxy.$message.error(res.message);
            handlerClose();
          }
        });
      if (localStorage.getItem("token") != null) {
        proxy.$post(proxy.URL.member.info, {}).then((res) => {
          if (res.code == 0) {
            state.userInfo = res.data;
          }
        });
      }
    };
    const handlerBuy = function () {
      state.showCon = false;
      setTimeout(() => {
        emit("handlerBuy");
      }, 500);
    };
    const handlerPay = function () {
      if (state.pasword == "") {
        proxy.$message.error(proxy.$t("planet.desc17"));
        return;
      }
      if (state.loading) {
        return;
      }
      state.loading = true;
      proxy
        .$post(proxy.URL.node.pay, {
          planetId: state.info.id,
          coinId: state.info.payCoinId,
          amount: state.info.price,
          password:
            state.userInfo.sourceType == 2 || state.userInfo.sourceType == 3
              ? state.pasword
              : proxy.common.mdpassword(state.pasword),
          recommendCode: state.recommendCode,
        })
        .then((res) => {
          state.loading = false;
          if (res.code == 0) {
            state.showSuccess = true;
            state.showpsd = false;
          } else {

            state.loading = false;
            state.pasword = "";
            state.showpsd = false;
            setTimeout(() => {
              state.showpsd = true;
            }, 100);
            proxy.$message.error(res.message);
          }
        });
    };
    const finish = function (val) {
      state.pasword = val;
    };
    const closeModal = () => {
      state.showRules = false;
    };
    const finish1 = function (val) {
      if (val == 0) {
        state.pasword = "";
      }
    };
    const handlerShowPay = function () {
      if (!state.check) {
        proxy.$message.error(proxy.$t("sign.desc38"));
        return;
      }
      if (localStorage.getItem("token") == null) {
        router.push("/login");
        return;
      }
      // if (!state.userInfo.hasBindGoogleAuth && !state.userInfo.hasSetPassword) {
      //   state.showGoogle = true;
      //   return;
      // }
      if (!state.userInfo.hasSetPassword) {
        state.showTrade = true;
        return;
      }
      state.showpsd = true;
    };
    const handlerSubmit = function (i) {
      state.showTrade = false;
      state.showGoogle = false;
      handlerClose();
      if (localStorage.getItem('ismobile') == 1) {
        if (i == 1) {
          router.push('/user/info?id=2')
        } else {
          router.push('/user/info?id=4')
        }
      } else {

        bus.emit("hadleropen", i);
      }


      return;
    };
    const getMoney = function (coinId) {
      proxy
        .$post(proxy.URL.assets.view, {
          relationCoinId: coinId,
          accountType: 1,
        })
        .then((res) => {
          if (res.code == 0) {
            if (state.userInfo.sourceType != 1) {
              state.userBalance = res.data.balance;
            } else {
              state.userBalance = state.info.balance;
            }
          }
        });
    };
    const handlerCheck = function () {
      state.check ? (state.check = false) : (state.check = true);
    };
    const handlerClose = function () {
      state.showSuccess = false;
      state.showCon = false;
      setTimeout(() => {
        emit("handlerClose");
      }, 500);
    };
    const handlerOther = function () {
      proxy
        .$post(proxy.URL.node.random, {
          typeId: props.level,
        })
        .then((res) => {
          if (res.code == 0) {
            state.showSuccess = false;
            state.info = res.data;
            state.userBalance = res.data.balance;
          }
        });
    };
    return {
      getPlanet,
      handlerPay,
      finish,
      finish1,
      handlerShowPay,
      handlerSubmit,
      getMoney,
      handlerCheck,
      handlerClose,
      handlerOther,
      closeModal,
      handlerBuy,
      handleInfo,
      handlerLogpsd,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.nodewrappar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: url("../public/images/new/infobg.png") top no-repeat;
  background-size: 100% 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px; //修改滚动条宽度
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }

  .stardetails-bg {
    position: relative;
    width: 1294px;
    height: 640px;
    margin: 117px auto 0;
    background: url("../public/images/new/infobgline.png") top no-repeat;
    background-size: 100% 100%;

    .paybtn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 249px;
      height: 50px;
      background: url("../public/images/new/paybtn.png") top no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      text-indent: 13px;
      cursor: pointer;
      z-index: 56;
    }

    .greybtn {
      background: url("../public/images/new/btnbg23.png") top no-repeat;
      background-size: 100% 100%;
    }

    .closeicon {
      position: absolute;
      top: 18px;
      left: 0;
      width: 44px;
      height: 44px;
      background: url("../public/images/new/close.png") center no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 56;
    }

    .slider-top {
      display: none;
    }

    .slider-left {
      flex: 0 0 471px;
      margin: 0 -33px 0 23px;

      .planet-title {
        padding: 121px 0 0;
        font-size: 24px;
        color: #fff;
      }

      .info-btn {
        margin: 30px 0 0 9px;
        width: 194px;
        height: 38px;
        background: url("../public/images/new/btnbg1.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
      }

      .planet-info {
        margin-top: 74px;
        padding-bottom: 30px;
        min-height: 287px;
        background: url("../public/images/new/leftbg.png") center no-repeat;
        background-size: 100% 100%;

        .info-desc {
          width: 295px;
          padding-top: 44px;
          margin-left: 18px;
          flex-wrap: wrap;

          .price {
            flex: 0 0 40%;
            margin-top: 24px;
            font-size: 12px;
            color: #ffffff;

            &:nth-child(1),
            &:nth-child(3) {
              flex: 0 0 60%;
            }

            &:nth-child(-n + 2) {
              margin-top: 0;
            }

            &:last-child {
              flex: 0 0 100%;
            }

            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }

            p {
              font-size: 24px;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .slider-center {
      position: relative;
      flex: 0 0 400px;
      height: 558px;
      margin-right: 60px;
      margin-top: 21px;

      // overflow: hidden;
      canvas {
        position: relative;
        z-index: 1;
      }

      .img {
        position: absolute;
        bottom: 52px;
        left: -50.5px;
        width: 489px;
        height: 240px;
      }

      .img1 {
        position: absolute;
        bottom: 104px;
        left: 0;
        width: 489px;
        height: 240px;
        border-radius: 50%;
      }
    }

    .slider-right {
      flex: 0 0 345px;
      padding-top: 60px;

      .slider-title {
        margin-bottom: 12px;
        font-size: 16px;
        color: #fff;
        line-height: 18px;

        img {
          width: 14px;
          height: 14px;
        }
      }

      .titletop {
        margin-top: 16px;
      }

      .icon-name {
        padding: 10px 16px;
        background: rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(255, 255, 255, 0.2) !important;
        font-size: 16px;
        color: #ffffff;
        line-height: 28px;

        p {
          flex: 1;
          text-align: right;
        }

        img {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          object-fit: cover;
        }
      }

      .slider-input {
        margin-bottom: 16px;
        padding: 0 17px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        font-size: 16px;
        color: #fff;
        line-height: 46px;

        input {
          width: 100%;
          height: 46px;
          font-size: 16px;
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }

        .input-div {
          font-size: 16px;
          line-height: 46px;
          color: rgba(255, 255, 255, 0.4);
        }
      }

      .slider-type {
        margin-bottom: 16px;
        justify-content: space-between;

        div {
          flex: 0 0 164px;
          height: 56px;
          background: url("../public/images/new/actbg.png") center no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          font-size: 14px;
          color: #000000;
          text-align: center;
          line-height: 59px;
        }

        // .act {
        //     background: url('../public/images/new/actbg.png') center no-repeat;
        //     background-size: 100% 100%;
        //     color: #000000;
        // }
      }

      .slider-price {
        .price-info {
          margin-top: 12px;
          justify-content: space-between;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 14px;

          p {
            color: #fff;

            img {
              width: 14px;
              height: 14px;
              margin-left: 4px;
              cursor: pointer;
            }
          }
        }
      }

      .slider-tips {
        margin-top: 12px;
        font-size: 14px;
        color: #f8a430;
        line-height: 16px;

        span {
          padding-left: 5px;
          color: #c9fa5b;
          cursor: pointer;
        }
      }

      .slider-check {
        margin-top: 33px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 16px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          cursor: pointer;
        }

        div {
          cursor: pointer;
        }
      }

      .slider-btn {
        display: none;
      }

      .detriment {
        padding: 0 32px;
        height: 433px;
        margin-top: 26px;
        background: url("../public/images/new/rightbg.png") center no-repeat;
        background-size: 100% 100%;

        .detriment-name {
          padding: 32px 0 12px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.6);
          line-height: 16px;
        }

        .detriment-user {
          height: 48px;
          font-size: 24px;
          color: #fff;
          line-height: 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .detriment-title {
          padding: 24px 0 12px;
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          line-height: 16px;
        }

        .detriment-desc {
          height: 122px;
          overflow: hidden;
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
          line-height: 14px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        .detriment-land {
          padding: 24px 0 12px;
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          line-height: 16px;
        }

        .detriment-num {
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
}

.el-dropdown {
  border: 0 !important;
}

.el-dropdown-link {
  width: 345px;
  padding: 12px 0;
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2) !important;

  .icon-name {
    position: relative;
    padding: 0 16px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    cursor: pointer;

    img {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      position: absolute;
      top: 0;
      right: 34px;
      font-size: 16px;
      color: #fff;
      line-height: 24px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      right: 16px;
      width: 8px;
      height: 6px;
      background: url("../public/images/new/loginicon1.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.coin-info {
  position: relative;
  display: flex;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  cursor: pointer;
  width: 100%;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }

  p {
    position: absolute;
    top: 0;
    right: 16px;
    font-size: 16px;
    color: #bbbbbb;
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .forgottitle {
    margin-top: 24px;
    font-size: 16px;
    color: #c9fa5b;
    text-align: right;
    cursor: pointer;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }

  .forgottitle {
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }
}

@media (max-width: 1200px) {
  .nodewrappar {
    z-index: 999;

    .stardetails-bg {
      width: calc(100vw - 8px);
      background: none;
      height: fit-content;
      margin: 25px auto 0;
      flex-wrap: wrap;
      justify-content: space-between;

      .closeicon {
        left: inherit;
        top: 0;
        right: 0;
      }

      .paybtn {
        display: none;
      }

      .slider-top {
        position: relative;
        display: block;
        flex: 0 0 100%;

        .top-name {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);

          p {
            margin-top: 8px;
            color: #fff;
          }
        }

        .top-img {
          position: relative;
          height: 280px;
          text-align: center;
          margin-left: -10px;

          .img {
            position: absolute;
            bottom: 0;
            left: 50%;
            height: 173px;
            transform: translateX(-50%);
          }

          .img1 {
            width: 240px;
            height: 240px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      .slider-center {
        display: none;
      }

      .slider-left {
        flex: 0 0 50%;
        margin: 0;

        .planet-title {
          padding-top: 30px;
        }

        .info-btn {
          margin-top: 16px;
          margin-left: 0;
        }

        .planet-info {
          margin-top: 20px;
          padding-bottom: 0;
          min-height: fit-content;
          background: none;

          .info-desc {
            padding-top: 0;
            margin-left: 0;
            width: 100%;
          }
        }
      }

      .slider-right {
        flex: 0 0 40%;
        padding-top: 32px;

        .slider-type {
          justify-content: flex-start;

          div {
            margin-right: 20px;
          }
        }

        .slider-btn {
          display: block;
          margin-top: 16px;
          height: 50px;
          background: url("../public/images/new/btnbg13.png") center no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          color: #111111;
          text-align: center;
          line-height: 50px;
        }

        .gretbtn {
          background: url("../public/images/new/btnbg24.png") center no-repeat;
          background-size: 100% 100%;
        }

        .detriment {
          padding: 0 20px;

          .detriment-name {
            padding: 20px 0 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .nodewrappar {
    .stardetails-bg {
      .slider-top {
        .top-img {
          width: 100%;
          margin-left: 0;
        }
      }

      .closeicon {
        left: inherit;
        top: 0;
        right: 0;
      }

      .slider-left {
        flex: 0 0 100%;

        .planet-title {
          font-size: 20px;
        }

        .planet-info {
          .info-desc {
            .price {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }

      .slider-right {
        margin-bottom: 20px;
        flex: 0 0 100%;

        .detriment {
          margin-top: 0;
          background: none;
          height: fit-content;
          padding: 0 16px 16px;
          border: 1px solid rgba(255, 255, 255, 0.2);

          .detriment-name,
          .detriment-title,
          .detriment-land {
            font-size: 14px;
            padding: 16px 0 12px;
          }

          .detriment-user,
          .detriment-desc,
          .detriment-num {
            font-size: 14px;
            padding-bottom: 8px;
            height: fit-content;
          }
        }

        .slider-title {
          font-size: 14px;
        }

        .titletop {
          margin-top: 16px;
        }
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }
}
</style>